/*

main body styles

*/
* {
  box-sizing: border-box;
}

body.light {
  --text-light: #777;
  --text-dark: #000;
  --text-inactive: #aaa;
  --comp-background: #fff;
  --body-background: #e6e6e6;
  --highlight-active: #0d71fc;
  --highlight-inactive: rgba(120, 120, 120, .1);
  --shadow-light: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  --bd-fix: #aaa;
  --icon: #777;
}

body.dark {
  --text-light: #fff;
  --text-dark: #eee;
  --text-inactive: #666;
  --comp-background: #2d2d30;
  --body-background: #1e1e1e;
  --highlight-active: #0d71fc;
  --highlight-inactive: rgba(120, 120, 120, .1);
  --bd-fix: #777;
  --icon: #fff;
}

body {
  --br-sm: 3px;
  --br-md: 5px;
  --br-lg: 10px;
  margin: 0;
  background-color: var(--body-background);
  color: var(--text-dark);
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

body.toggle {
  transition: color .4s ease-in-out;
  transition: background-color .2s ease-in-out;
}

/*
main header styles
*/
header {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: var(--comp-background);
  box-shadow: var(--shadow-light);
  z-index: 4;
  overflow: hidden;
}

#wrapper-header {
  display: flex;
  align-items: center;
  width: 90%; 
}

#mode-toggle {
  height: 20px;
  width: 20px;
  margin-left: 45px;
  cursor: pointer;
}

#mode-toggle path {
  fill: var(--icon);
}

#logo {
  width: 200px;
  height: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: -100px;
  margin-top: 2px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./img/logo.png');
  background-size: cover;
}

#logo-dark {  
  width: 200px;
  height: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: -100px;
  margin-top: 2px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./img/logo-dark.png');
  background-size: cover;
  
}


@media only screen and (min-width: 1400px) {
  #mode-toggle {
      margin-left: 10%;
  }
}

@media only screen and (min-width: 1700px) {
  #mode-toggle {
      margin-left: 10.5%;
  }
}

@media only screen and (min-width: 1820px) {
  #mode-toggle {
      margin-left: 11.5%;
  }
}

@media only screen and (min-width: 1900px) {
  #mode-toggle {
      margin-left: 13%;
  }
}

@media only screen and (min-width: 2000px) {
  #mode-toggle {
      margin-left: 15%;
  }
}

/*
controller styles
*/
#controller {
  background-color: var(--comp-background);
  width: 60px;
  height: 540px;
  margin: 40px 0 0 25px;
  padding: 10px 0 10px 0;
  border-radius: var(--br-md);
  overflow: hidden;
  box-shadow: var(--shadow-light);
}

#controller-divider {
  width: 15px;
  height: 1px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 50px;
  background-color: var(--text-light);
  opacity: .2;
}


#controller ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding: 0;
}

#controller ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-bottom: 25px;
  padding: 0;
}

#controller .active {
  margin-left: 19px;
}

.controller--icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.controller--icon path {
  fill: var(--icon);
  transition: all .3s ease-in-out;
}

.active path {
  fill: var(--highlight-active);
}

li span {
  opacity: 0;
}

.active span {
  height: 40px;
  width: 3px;
  background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
  border-radius: 99px;
  margin-left: 16px;
  opacity: 1;
  transition: opacity .3s ease-out;
}

.user-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: auto;
  margin-top: 25px;
  background-color:rgb(231, 254, 229);
  border-radius: 50%;
  font-size: 13px;
  color:rgb(81, 149, 75);
  cursor: alias;
}

.user-icon svg {
  width: 15px;
  height: 15px;
}

.user-icon path {
  fill: var(--icon);
}

#el {
  background-color: rgb(254, 229, 229);
  color:rgb(149, 75, 75);
}

#rr {
  background-color: rgb(229, 238, 254);
  color:rgb(75, 90, 149);
}

/*
main container styles (frame container)
*/
#main-container {
  /* position: absolute; */
  margin-top: 25px;
  left: 145px;
  width: 80%;
  max-width: 1200px;
  /* max-height: 83vh; */
  transition: display .2 ease-in-out;
}

#main-container h2 {
  margin: 15px 0 10px 0;
  font-size: 20px;
}

.main-container-subheader {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-light);
}

#wrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

@media only screen and (min-width: 1400px) {
  #wrapper {
      display: flex;
      justify-content: center;
      gap: 40px;
      max-width: 90%;
      margin-left: 2%;
  }
  
  #main-container {
      position: relative;
      left: 20px;
      /* top: 40px; */
      width: 80%;
      max-width: 1300px;
      /* height: 83vh; */
      margin-left: 0;
  }

  #controller {
      margin-left: 0;
  }
}

/*
ui:batch and drop
*/
.component {
  margin-top: 10px;
}

.batch-results-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.batch-results-controls {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.batch-results-controls .icon-clear, .icon-send {
  margin-right: 10px;
}

.icon-send {
  width: 20px;
  height: 20px;
}

.batch-results-legend {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: 0px;
}

.legend-item {
  display: flex;
  cursor: pointer;
}

.legend-clear-filter {
  text-decoration: underline;
  text-decoration-color: var(--text-light);
}

.legend-item-flag {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.legend-item-label {
  margin: -2px 0 0 3px !important;
  padding-right: 10px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: --var(--text-light);
}


body.light #batch-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 390px;
  min-height: 200px;
  border-radius: var(--br-md);
  box-shadow: rgb(212, 212, 212) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  color: var(--text-light);
}

body.dark #batch-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 390px;
  min-height: 200px;
  border-radius: var(--br-md);
  box-shadow: rgb(22, 22, 22) 3px 3px 6px 0px inset, rgba(102, 102, 102, 0.5) -3px -3px 6px 1px inset;
  color: var(--text-light);
}

#batch-placeholder-title {
  font-weight: 300;
  margin: 0;
}

#batch-placeholder-icon {
  width: 150px;
  height: 150px;
  margin-top: -20px;
  stroke-width: .15;
}

#batch-placeholder-icon path {
  /* fill: var(--text-light); */
  stroke: var(--text-light);
}

#batch-file-list {
  width: 100%;
  max-height: 405px;
  padding-right: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#batch-file-list::-webkit-scrollbar {
  display: none;
}


.dotify-batch-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 45px;
  margin-bottom: 10px;
  background-color: var(--comp-background);
  border-radius: var(--br-md);
  box-shadow: var(--shadow-light);
}

.batch-flag {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-radius: 50%;
}

.flag-dotified, #label-dotified {
  background: linear-gradient(180deg, rgb(75, 145, 247) 0%, rgb(54, 122, 246) 100%);
}

.flag-modified, #label-modified {
  background: linear-gradient(180deg, rgb(77, 243, 88) 0%, rgb(56, 220, 87) 100%);
}

.flag-error, #label-error {
  background: linear-gradient(180deg, rgb(254, 77, 77) 0%, rgb(233, 54, 76) 100%);
}

.flag-unchanged, #label-unchanged {
  background: linear-gradient(180deg, rgb(151, 151, 151) 0%, rgb(102, 102, 102) 100%);
}

.batch-item-title {
  width: 80%;
  min-width: 100px;
  font-size: 15px;
  font-weight: 400;
  margin-left: 20px;
  color: var(--text-light);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.item-char-count {
  margin-right: 15px;
  font-size: 12px;
  vertical-align: text-top;
}

.item-icons {
  margin-left: auto;
  margin-right: 5px;
  margin-top: 5px;
  white-space: nowrap;
}

.icon-copy {
  margin-right: 15px;
  cursor: pointer;
}

.downloading {
  animation: downloading  .5s ease-in-out infinite;
}

@keyframes downloading {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(-360deg);
  }
}

@media only screen and (max-width: 816px) {
  .legend-item-label span {
      display: none;
  }
}

/*
ui:drop zone
*/
#dotify-multi {
  flex-grow: .2;
}

body.light #dotify-drop-input {
  width: 100%;
  min-width: 150px;
  height: 150px;
  padding: 10px;
  border-radius: var(--br-md);
  box-shadow: rgb(212, 212, 212) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

body.dark #dotify-drop-input {
  width: 100%;
  min-width: 150px;
  height: 150px;
  padding: 10px;
  border-radius: var(--br-md);
  box-shadow: rgb(22, 22, 22) 3px 3px 6px 0px inset, rgba(102, 102, 102, 0.5) -3px -3px 6px 1px inset;
}

#dotify-drop-input {
  text-align: center;
}

#drop-area-label {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: -100px;
  border-radius: var(--br-md);
  color: var(--text-light);
  cursor: pointer;
}

#dotify-drop-input h2 {
  margin-top: 37px;
  margin-bottom: 0px;
  font-weight: 300;
}

#dotify-drop-input .controller--icon {
  margin: auto;
  margin-top: 10px;
}

#drop-area {
  display: none;
}

.icon-drag-over path {
  fill: var(--highlight-active);
  transition: fill .2s ease-in-out;
}


/*
frame:dotify styles
*/
.component-input-container {
  display: flex;
  gap: 40px;
  width: 100%;
  padding-right: 5px;
}
#dotify-batch-header {
  margin-top: 40px !important;
}

.flash {
  position: absolute;
  margin-left: 10px;
  margin-top: 5px;
  padding: 2px 5px 2px 5px;
  font-size: 10px;
  border-radius: 5px;
  color: var(--text-dark);
  opacity: 0;
}
.flash-copy {
  background-color: yellow;
  animation: flash 2s ease-in-out;
}

.flash-error {
  background-color: red;
  animation: flash 2s ease-in-out;
}

@keyframes flash {
  0%, 100% {
      opacity: 0;
  }
  25%, 75% {
      opacity: 1;
  }
}

.dotify-clear {
  width: 20px;
  height: 20px;
  margin: 5px 5px 0 auto;
  opacity: 0;
}

.dotify-clear-hide {
  opacity: 1;
  transition: opacity .2s ease-in-out;
  cursor: pointer;
}

.dotify-clear path {
  fill: var(--text-light);
}

#dotify-single, #remix-input-container {
  flex-grow: 2;
}

#dotify-single-box, .tools--input {
  background-color: var(--comp-background);
  display: flex;
  flex-direction: column;
  max-width: 900px;
  min-width: 300px;
  height: 150px;
  border-radius: var(--br-md);
  box-shadow: var(--shadow-light);
}

#dotify-single-input {
  border: 0px solid black;
  width: 90%;
  height: 25px;
  margin: auto;
  margin-top: 21px;
  margin-bottom: 15px;
  padding: 0px 5px 0px 5px;
  border-radius: 5px;
  overflow: hidden;
  font-size: 12px;
  vertical-align: baseline; 
}

body.dark #dotify-single-input,
body.dark .remix-input {
  background-color: var(--body-background);
  color: var(--text-light);
}
body.light #dotify-single-input,
body.light .remix-input {
  background-color: var(--body-background);
  color: var(--text-dark);
}

#dotify-single-input:focus {
  border: none;
  outline: none;
  box-shadow: 0 0 0 1px var(--highlight-active);
}

#dotify-button-container {
  display: flex;
  align-items: center;
  height: 27px;
  width: 85%;
  margin: auto;
  margin-top: 0px;
}

#dotify-char-count span.valid {
  font-weight: 700;
  color: rgb(9, 201, 2);
}

#dotify-char-count span.invalid {
  font-weight: 700;
  color: rgb(174, 0, 0);
}

.button-dotify {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 27px;
  padding: 6px 14px;
  border-radius: var(--br-md);
  border: none;
  letter-spacing: .8px;
  color: #fff;
  background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
  cursor: pointer;
}

.button-dotify:disabled {
  background: var(--highlight-inactive);
  color: var(--text-inactive);
  box-shadow: none;
  cursor: default;
}

#toggle-type {
  display: flex;
  margin-left: auto;
  margin-right: 30px;
  margin-top: 0px;
}

#toggle-value {
  font-size: 10px; 
  margin-right: 10px;
}

#toggle-background {
  background-color: #eee;
  width: 30px;
  height: 15px;
  border-radius: 100px;
}

#toggle-foreground {
  position: absolute;
  background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
  width: 17px;
  height: 17px;
  margin-top: -1px;
  margin-left: -1px;
  border-radius: 50%;
  transition: all .3s ease;
  cursor: pointer;
}

.toggle-checked {
  transform: translateX(15px);
  background-color: var(--highlight-active) !important;
}

@media only screen and (max-width: 900px) {
  #main-container {
      width: 70%;
  }

  .component-input-container {
      flex-direction: column;
      row-gap: 20px;
  }

  #dotify-single {
      width: 100%;
  }

  #dotify-multi {
      width: 100%;
  }
}

/*
frame:remix
*/
.remix-help {
  position: relative;
  top: 1px;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  color: var(--highlight-active);
  cursor: pointer;
}

.remix-input {
  border: 0px solid black;
  width: 90%;
  height: 25px;
  margin: 15px auto 0 auto;
  padding: 0px 5px 0px 5px;
  border-radius: 5px;
  overflow: hidden;
  font-size: 12px;
  vertical-align: baseline; 
}

.remix-input:focus {
  border: none;
  outline: none;
  box-shadow: 0 0 0 1px var(--highlight-active);
}

#remix-input-from {
  margin-top: 25px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto 0 auto;
}

.regex-history {
  margin-left: 5px;
}

#regex-button {
  width: 70px;
}

.remix-dot-seperator {
  font-size: 8px;
}

/*
frame:calculon
*/
.calculon-modules-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  max-height: 700px;
  max-width: 1300px;
  margin-top: 10px;
}

.calculon-component {
  background-color: var(--comp-background);
  margin-top: 20px;
  padding: 10px;
  border-radius: var(--br-md);
  box-shadow: var(--shadow-light);
  min-width: 380px;
}

.calculon-clear {
  position: absolute;
  width: 18px;
  height: 18px;
  opacity: 0;
  cursor: pointer;
}

.calculon-clear-hide {
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.calculon-clear path {
  fill: var(--icon);
}

.calculon-input {
  width: 35px;
  height: 25px;
  outline: none;
  border: 1px solid var(--bd-fix);
  text-align: center;
  font-size: 13px;
  background-color: var(--comp-background);
  color: var(--text-light);
}

.calculon-input:focus {
  outline: none;
}

#mod-minutes {
  margin-left: 10px;
  padding-right: 3px;
  border-top-left-radius: var(--br-sm);
  border-bottom-left-radius: var(--br-sm);
  border-right: none;
}

#mod-seconds, #seconds-start, #seconds-stop {
  margin-right: 10px;
  border-left: none;
  border-top-right-radius: var(--br-sm);
  border-bottom-right-radius: var(--br-sm);
}

#hours-start, #hours-stop {
  margin-left: 10px;
  padding-right: 3px;
  border-right: none;
  border-top-left-radius: var(--br-sm);
  border-bottom-left-radius: var(--br-sm);
}

#minutes-start, #minutes-stop {
  border-left: none;
  border-right: none;
}

#hours-one, #hours-two, #hours-three {
  margin-left: 10px;
  border-right: none;
  border-top-left-radius: var(--br-sm);
  border-bottom-left-radius: var(--br-sm);
}

#seconds-one, #seconds-two, #seconds-three {
  margin-right: 10px;
  border-left: none;
  border-top-right-radius: var(--br-sm);
  border-bottom-right-radius: var(--br-sm);
}

#minutes-one, #minutes-two, #minutes-three {
  border-left: none;
  border-right: none;
}

#h-time-1,
#h-time-2,
#h-time-3,
#h-time-4,
#h-time-5,
#h-time-6,
#h-time-7,
#h-time-8 {
  margin-left: 10px;
  border-right: none;
  border-top-left-radius: var(--br-sm);
  border-bottom-left-radius: var(--br-sm);
}

#m-time-1,
#m-time-2,
#m-time-3,
#m-time-4,
#m-time-5,
#m-time-6,
#m-time-7,
#m-time-8 {
  border-left: none;
  border-right: none;
}

#s-time-1,
#s-time-2,
#s-time-3,
#s-time-4,
#s-time-5,
#s-time-6,
#s-time-7,
#s-time-8 {
  border-left: none;
  border-top-right-radius: var(--br-sm);
  border-bottom-right-radius: var(--br-sm);
}


#modulate, #diff, #controlled-score {
  display: flex;
}

#modulate, #diff {
  height: 100px;
}

#modulate-result, #diff-result, #controlled-score-result, #add-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin: 0 auto 0 25px;
  color: var(--text-light);
}


#controlled-score {
  height: 130px;
}

#controlled-score-result, #modulate-result, #diff-result, #add-result {
  height: 80px;
}

#modulate h2, #diff h2, 
#controlled-score-result h2, #add-result h2 {
  font-weight: 300;
  font-size: 15px; 
  margin: 0;
}

#controlled-score-result div, #modulate-result div, 
#diff-result div, #add-result div {
  width: 100px;
  border-bottom: 1px solid var(--text-light); 
}

#add .input-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  gap: 10px;
  margin: auto;
  max-height: 150px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.input-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: right;
  margin-right: 20px;
  margin-top: 1px;
}

.input-wrapper div {
  margin-left: auto;
}


.input-labels {
  font-size: 13px;
}

#container-add-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

button#add-more {
  justify-content: center;
  margin-top: 19px;
  padding: 6px 14px;
  border-radius: var(--br-md);
  border: none;
  letter-spacing: .8px;
  color: #fff;
  background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
  cursor: pointer;
}

#add-result {
  margin-left: 20px;
  color: var(--text-light);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; 
}

@media only screen and (max-width: 961px) {
  .calculon-modules-container {
      column-gap: 20px;
  }
}

@media only screen and (max-width: 930px) {
  .calculon-modules-container {
      column-gap: 10px;
  }
}

@media only screen and (max-width: 900px) {
  .calculon-modules-container {
      flex-wrap: nowrap;
      max-height: 100%;
  }
}

/*
header: searchbar styles
*/
.loading-spinner {
  display: block;
  margin: auto;
  margin-top: 40px;
  width: 20px;
  height: 20px;
  animation: spin .7s ease-in-out infinite;
}

.loading-spinner path {
  fill: var(--icon);
}

@keyframes spin {
  0% {
      tranform: rotate(0deg);
  }

  100% {
      transform: rotate(-360deg);
  }
}


#container--search {
  display: flex;
  align-items: center;
  margin-left: 80px;
}

#icon-search {
  height: 13px;
  width: 13px;
  margin-right: 10px;
  cursor: pointer;
}

/* .animate-icon-in {
  cursor: pointer;
  transform: translateX(400px);
  transition: all .3s ease-in-out;
}

.animate-icon-out {
  transform: translateX(0);
  transition: all .2s ease-in-out; 
} */

#icon-search path {
  fill: var(--highlight-active);
}

#input--search {
  height: 25px;
  padding: 2px 10px 2px 10px;
  outline: none;
  border: none;
  font-size: 13px;
  background-color: var(--comp-background);
  color: var(--text-light);
  transition: width .3s ease-in-out;
  transition: box-shadow .3s ease-in-out;
}

#input--search::placeholder {
  color: var(--text-light);
}

.search-focus {
  width: 400px;
  box-shadow: 0 1px 0 0 var(--highlight-active);
}


.blur {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: rgba(0,0,0,.7);
  z-index: 2;
  transition: all .2s ease-in-out;
}

.hide {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: rgba(0,0,0,0);
  z-index: 0;
  transition: all .2s ease-in-out;
  visibility: hidden;
}


/* Search Results */
.search-results {
  position: absolute;
  top: 50px;
  width: 400px;
  min-height: 0px;
  margin-left: 15px;
  padding-bottom: 5px;
  background-color: var(--comp-background);
  border-radius: 0 0 5px 5px;
  z-index: 3;
  visibility: hidden;
}

.search-results div:last-child {
  border-bottom: none;
}

.sr-animate {
  visibility: visible;
  min-height: 100px;
  transition: height .1s ease-in-out;
}


/* Search Items */
.search-item {
  display: flex;
  width: 400px;
  height: 85px;
  padding: 5px;
  border-bottom: 1px solid var(--text-light);
  cursor: pointer;
}

.search-item--img {
  margin-right: 15px; 
  border-radius: 5px;
  border: 1px solid #000;
}

.search-item--data {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 10px 10px 0;
}

.search-item--meta {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.search-item--title {
  margin: 0;
  font-size: 15px;
  /* font-weight: 300; */
}

.search-item p {
  margin: 0;
  font-size: 13px;
}

.search-item--type {
  justify-self: flex-end;
}

/* IMDb Data */

#imdb-data {
  position: absolute;
  background-color: var(--comp-background);
  top: 100px;
  left: calc(50vw - 410px);
  max-height: 80%;
  width: 820px;
  padding: 10px;
  border-radius: var(--br-md);
  z-index: 3;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#imdb-data::-webkit-scrollbar {
  display: none;
}



/****** IMDb Results ********/
#pd-general {
  background-color: var(--comp-background);
  padding: 15px;
  border-radius: 3px;
}

.section-title {
  margin: 0px 0 10px 0 !important;
  font-size: 15px !important;
  font-weight: 500;
}

.title-error {
  margin: auto;
}

.copy-content {
  cursor: copy;
}

.open-page {
  cursor: pointer;
}

.pd-wrapper {
  display: flex;
  gap: 10px;
}

#pd-wrapper-left {
  width: 50%;
  gap: 15px;
  margin-left: 1px;
  justify-content: left;
  color: var(--text-light);
}

#pd-sub-wrapper {
  display: flex;
  gap: 10px;
}

#pd-wrapper-right {
  width: 50%;
  gap: 15px;
  margin-left: 1px;
  justify-content: left;
  color: var(--text-light);
}

.pd-label-box {
  height: 30px;
  padding-left: 10px;
  margin-bottom: 10px;
  border: 1px solid #999;
  border-radius: 3px;
  font-size: 12px;
  color: var(--text-light);
}

.pd-label {
  display: block;
  width: fit-content;
  position: relative;
  top: -7px;
  left: -1px;
  padding: 0 2px 0 2px;
  background-color: var(--comp-background);
  font-size: 8px;
}

.pd-box-content {
  margin: -5px 0 0 0 !important;
}

#pd-category {
  width: 35%;
}

#pd-eidr {
  width: 65%;
}

#pd-imdb {
  width: 50%;
}

.hr {
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
  background-color: rgba(0,0,0,.1);
}

.pd-wrapper-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#pd-language, #pd-year, #pd-territory,
#pd-country, #pd-company, #pd-property {
  width: 24%;
}

.pd-wrapper-3 {
  display: flex;
  gap: 10px;
  margin-bottom: 3px;
}

.pd-participant {
  width: 49.5%;
}

.pd-capacity {
  width: 30%;
}

.pd-wrapper-4 {
  display: flex;
  gap: 10px;
}

.pd-alt-titles {
  width: 60%;
}

.pd-title-article {
  width: 20%;
}

.pd-title-type {
  width: 10%;
}

.pd-title-lang {
  width: 10%;
}

/* lang codes */
.hide-lang {
  display: none !important;
}

/* changelong */
.change-container {
  margin-top: 15px;
  border-left: 1px solid var(--text-light);
}

.change-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  padding-bottom: 30px;
}

.change-row p {
  margin: 0;
}

.change-col-left {
  width: 30%;
  padding-left: 20px;
}

.change-col-right {
  width: 70%;
  max-width: 700px;
}

.change-date{
  font-size: 12px;
}

.change-date::before {
  content: "🔵 ";
  margin-left: -25px;
  padding-right: 14px;
  font-size: 8px;
}

.change-title {
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
}

.change-version {
  width:fit-content;
  border-radius: 2px;
  padding: 5px;
  background-color: #97cbff;
  color: #1500ff;
  font-size: 10px;
  text-align: center;
}

.change-description {
  padding-top: 10px;
  font-size: 14px;
}

.changelog-img {
  width: 100%;
  max-width: 700px;
  height: auto;
  border: 1px solid black;
  border-radius: 15px;
}


/* footer */
#copyright, #version {
  text-align: center;
  vertical-align: bottom;
  font-size: 10px;
  font-weight: 400;
  color: var(--text-light);
}

#version {
  margin: 33px 0 0 -35px;
  font-size: 8px;
}